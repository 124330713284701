
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import HotelTable from "@/components/hotel/List.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

//
export default defineComponent({
  name: "hotel-settings",
  components: {
    HotelTable,
  },
  props:{
    setValue: String,
    setCountry: String,
  },
  data: function () {
    return {
      myLoading: false,
    }
  },
  methods: {},
  watch: {
    setValue(e){
      this.myLoading = true;
     // store.dispatch(Actions.HOTEL_LIST_ALL, {'Title': e, 'Country': this.setCountry})   /* Ülke Başlıkları ListHeader3.vue dosyasında */
      store.dispatch(Actions.HOTEL_LIST_ALL, {'Title': e, 'Country': 'TRNC'})
          .then(() => {
            this.myList = store.getters.hotelList;
            this.myLoading = false;
          });
    },
    setCountry(e){
      this.myLoading = true;
      //store.dispatch(Actions.HOTEL_LIST_ALL, {'Title': this.setValue, 'Country': e})
      store.dispatch(Actions.HOTEL_LIST_ALL, {'Title': this.setValue, 'Country': 'TRNC'})
          .then(() => {
            this.myList = store.getters.hotelList;
            this.myLoading = false;
          });
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Oteller Listesi", ["Oteller", "Liste"]);
    });
   // store.dispatch(Actions.HOTEL_LIST_ALL, {'Title': 'A', 'Country': 'TR'});
    store.dispatch(Actions.HOTEL_LIST_ALL, {'Title': 'A', 'Country': 'TRNC'});
    const myList = computed(() => {
      return store.getters.hotelList;
    });
    return {
      myList,
    };
  },
});
